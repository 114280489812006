import { useState } from 'react'
import Header from './components/Header'
import Tasks from './components/Tasks'

import './styles/styles.scss'

const App = () => {
  const [tasks, setTasks] = useState([
    {
      id: 1,
      text: 'Task 1',
      date: 'Jan 1, 2020',
      reminder: true
    },
    {
      id: 2,
      text: 'Task 2',
      date: 'Feb 1, 2020',
      reminder: true
    },
    {
      id: 3,
      text: 'Task 3',
      date: 'Mar 1, 2020',
      reminder: false
    },
  ])

  const deleteTask = (id) => {
    setTasks(tasks.filter( (task) => task.id !== id))
  }

  return (
      <div className="App">
        <div className="container">
          <Header title='Task Tracker'/>
          <Tasks tasks={tasks} onDelete={deleteTask} />
        </div>
      </div>
  )
}

export default App
