import { FaTimes } from 'react-icons/fa'

const Task = ({ task, onDelete }) => {
  return (
    <div style={{ padding: '0.5rem 1rem', marginBottom: '0.25rem', backgroundColor: '#ccc' }}>
      <h3 style={{marginTop: '0', marginBottom: '0.25rem'}}>
        {task.text}{' '}
        <FaTimes style={{cursor: 'pointer', float: 'right', color: 'red'}} onClick={() => onDelete(task.id )}/>
      </h3>
      <p style={{marginTop: '0', marginBottom: '0.25rem'}}>{task.date}</p>
    </div>
  )
}

export default Task
